import { DispositionItemResource } from '@data/authorization/authorization.model';
import {
  SuborderItemResource,
  SuborderItemType,
} from '@data/orders-with-reservation/suborders/orders-with-reservation-suborders.model';
import { Id } from '@core/http/crud-model';
import { ucfirst } from '@shared/utils/ucfirst';

export class DispositionItem {
  constructor(public item: DispositionItemResource) {}

  confirmedVariant(): SuborderItemResource {
    return this.item.suborder.items.find((i) => i.type === SuborderItemType.CONFIRMED_VARIANT);
  }

  confirmedVariantIndex(): number {
    return this.item.suborder.items.findIndex((i) => i.type === SuborderItemType.CONFIRMED_VARIANT);
  }

  confirmedSubstituteIndex(): number {
    return this.item.suborder.items.findIndex((i) => i.type === SuborderItemType.CONFIRMED_SUBSTITUTE);
  }

  toSelect(item: SuborderItemResource): {
    dispositionItemAuthId: Id;
    suborderItemId: Id;
  } {
    return {
      dispositionItemAuthId: this.item.auth.id,
      suborderItemId: item.id,
    };
  }

  getPurchaseSumPrice(): number {
    return +this.item.price_wholesaler_calculated || +this.item.price_workshop_calculated;
  }

  getPurchasePrice(): number {
    return +this.item.price_wholesaler_calculated
      ? +this.item.price_wholesaler_calculated / +this.item.quantity
      : +this.item.price_workshop;
  }

  getSaleSumPrice(): number {
    return +this.item.price_fleet_calculated;
  }

  getSalePrice(): number {
    return +this.item.price_fleet;
  }

  getQuantity(): number {
    return +this.item.quantity;
  }

  isProduct(): boolean {
    return this.item?.service?.is_product;
  }

  getAuthId(): Id {
    return this.item?.auth?.id;
  }

  getAuthCode(): string {
    return this.item?.auth?.code;
  }

  getDispositionId(): Id {
    return this.item?.disposition_id;
  }

  getId(): Id {
    return this.item?.id;
  }

  getDamageTypeName(): string {
    return ucfirst(this.item?.damage_type?.name);
  }
}

export interface DispositionItemPatchRequest {
  quantity?: number;
  price_fleet?: number;
  price_workshop?: number;
  vat_rate?: number;
  description?: string;
  producer?: string;
  catalog_number?: string;
  part_type?: string;
}

export interface DispositionItemPostRequest {
  service_id: Id;
  quantity: number;
  price_fleet: number;
  price_workshop: number;
  description?: string;
  producer?: string;
  catalog_number?: string;
  part_type?: string;
}

export interface DispositionItemBatchPostRequest {
  items: DispositionItemPostRequest[];
}
