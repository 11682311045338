import { ElementRef, NgZone } from '@angular/core';
import { normalizePassiveListenerOptions, Platform } from '@angular/cdk/platform';
import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';
import { coerceElement } from '@angular/cdk/coercion';
import { RippleConfig, RippleRef, RippleState } from './ripple-ref';

export interface RippleTarget {
  rippleConfig: RippleConfig;
  rippleDisabled: boolean;
}

export const defaultRippleAnimationConfig = {
  enterDuration: 450,
  exitDuration: 400,
};

const ignoreMouseEventsTimeout = 800;
const passiveEventOptions = normalizePassiveListenerOptions({ passive: true });
const pointerDownEvents = ['mousedown', 'touchstart'];
const pointerUpEvents = ['mouseup', 'mouseleave', 'touchend', 'touchcancel'];

export class RippleRenderer implements EventListenerObject {
  private containerElement: HTMLElement;
  private triggerElement: HTMLElement | null;
  private isPointerDown = false;
  private activeRipples = new Set<RippleRef>();
  private mostRecentTransientRipple: RippleRef | null;
  private lastTouchStartEvent: number;
  private pointerUpEventsRegistered = false;
  private containerRect: ClientRect | null;

  constructor(
    private target: RippleTarget,
    private ngZone: NgZone,
    elementOrElementRef: HTMLElement | ElementRef<HTMLElement>,
    platform: Platform,
  ) {
    if (platform.isBrowser) {
      this.containerElement = coerceElement(elementOrElementRef);
    }
  }

  fadeInRipple(x: number, y: number, config: RippleConfig = {}): RippleRef {
    const containerRect = (this.containerRect = this.containerRect || this.containerElement.getBoundingClientRect());
    const animationConfig = {
      ...defaultRippleAnimationConfig,
      ...config.animation,
    };

    if (config.centered) {
      x = containerRect.left + containerRect.width / 2;
      y = containerRect.top + containerRect.height / 2;
    }

    const radius = config.radius || distanceToFurthestCorner(x, y, containerRect);
    const offsetX = x - containerRect.left;
    const offsetY = y - containerRect.top;
    const duration = animationConfig.enterDuration;

    const ripple = document.createElement('div');
    ripple.classList.add('wchfs-ripple-element');

    ripple.style.left = `${offsetX - radius}px`;
    ripple.style.top = `${offsetY - radius}px`;
    ripple.style.height = `${radius * 2}px`;
    ripple.style.width = `${radius * 2}px`;
    if (config.color != null) {
      ripple.style.backgroundColor = config.color;
    }

    ripple.style.transitionDuration = `${duration}ms`;

    this.containerElement.appendChild(ripple);

    enforceStyleRecalculation(ripple);

    ripple.style.transform = 'scale(1)';

    const rippleRef = new RippleRef(this, ripple, config);

    rippleRef.state = RippleState.FADING_IN;
    this.activeRipples.add(rippleRef);

    if (!config.persistent) {
      this.mostRecentTransientRipple = rippleRef;
    }
    this.runTimeoutOutsideZone(() => {
      const isMostRecentTransientRipple = rippleRef === this.mostRecentTransientRipple;
      rippleRef.state = RippleState.VISIBLE;
      if (!config.persistent && (!isMostRecentTransientRipple || !this.isPointerDown)) {
        rippleRef.fadeOut();
      }
    }, duration);
    return rippleRef;
  }

  fadeOutRipple(rippleRef: RippleRef): void {
    const wasActive = this.activeRipples.delete(rippleRef);

    if (rippleRef === this.mostRecentTransientRipple) {
      this.mostRecentTransientRipple = null;
    }

    if (!this.activeRipples.size) {
      this.containerRect = null;
    }

    if (!wasActive) {
      return;
    }

    const rippleEl = rippleRef.element;
    const animationConfig = {
      ...defaultRippleAnimationConfig,
      ...rippleRef.config.animation,
    };

    rippleEl.style.transitionDuration = `${animationConfig.exitDuration}ms`;
    rippleEl.style.opacity = '0';
    rippleRef.state = RippleState.FADING_OUT;

    this.runTimeoutOutsideZone(() => {
      rippleRef.state = RippleState.HIDDEN;
      rippleEl.parentNode!.removeChild(rippleEl);
    }, animationConfig.exitDuration);
  }

  fadeOutAll(): void {
    this.activeRipples.forEach((ripple) => ripple.fadeOut());
  }

  setupTriggerEvents(elementOrElementRef: HTMLElement | ElementRef<HTMLElement>): void {
    const element = coerceElement(elementOrElementRef);
    if (!element || element === this.triggerElement) {
      return;
    }
    this.removeTriggerEvents();
    this.triggerElement = element;
    this.registerEvents(pointerDownEvents);
  }

  handleEvent(event: Event): void {
    if (event.type === 'mousedown') {
      this.onMousedown(event as MouseEvent);
    } else if (event.type === 'touchstart') {
      this.onTouchStart(event as TouchEvent);
    } else {
      this.onPointerUp();
    }
    if (!this.pointerUpEventsRegistered) {
      this.registerEvents(pointerUpEvents);
      this.pointerUpEventsRegistered = true;
    }
  }

  removeTriggerEvents(): void {
    if (this.triggerElement) {
      pointerDownEvents.forEach((type) => {
        this.triggerElement!.removeEventListener(type, this, passiveEventOptions);
      });
      if (this.pointerUpEventsRegistered) {
        pointerUpEvents.forEach((type) => {
          this.triggerElement!.removeEventListener(type, this, passiveEventOptions);
        });
      }
    }
  }

  private onMousedown(event: MouseEvent): void {
    const isFakeMousedown = isFakeMousedownFromScreenReader(event);
    const isSyntheticEvent =
      this.lastTouchStartEvent && Date.now() < this.lastTouchStartEvent + ignoreMouseEventsTimeout;

    if (!this.target.rippleDisabled && !isFakeMousedown && !isSyntheticEvent) {
      this.isPointerDown = true;
      this.fadeInRipple(event.clientX, event.clientY, this.target.rippleConfig);
    }
  }

  private onTouchStart(event: TouchEvent): void {
    if (!this.target.rippleDisabled) {
      this.lastTouchStartEvent = Date.now();
      this.isPointerDown = true;
      const touches = event.changedTouches;
      for (let i = 0; i < touches.length; i++) {
        this.fadeInRipple(touches[i].clientX, touches[i].clientY, this.target.rippleConfig);
      }
    }
  }

  private onPointerUp(): void {
    if (!this.isPointerDown) {
      return;
    }
    this.isPointerDown = false;
    this.activeRipples.forEach((ripple) => {
      const isVisible =
        ripple.state === RippleState.VISIBLE ||
        (ripple.config.terminateOnPointerUp && ripple.state === RippleState.FADING_IN);

      if (!ripple.config.persistent && isVisible) {
        ripple.fadeOut();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  private runTimeoutOutsideZone(fn: Function, delay = 0): void {
    this.ngZone.runOutsideAngular(() => setTimeout(fn, delay));
  }

  private registerEvents(eventTypes: string[]): void {
    this.ngZone.runOutsideAngular(() => {
      eventTypes.forEach((type) => {
        this.triggerElement!.addEventListener(type, this, passiveEventOptions);
      });
    });
  }
}

function enforceStyleRecalculation(element: HTMLElement): void {
  window.getComputedStyle(element).getPropertyValue('opacity');
}

function distanceToFurthestCorner(x: number, y: number, rect: ClientRect): number {
  const distX = Math.max(Math.abs(x - rect.left), Math.abs(x - rect.right));
  const distY = Math.max(Math.abs(y - rect.top), Math.abs(y - rect.bottom));
  return Math.sqrt(distX * distX + distY * distY);
}
