import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthState } from '@data/auth/auth-state';
import { AuthStateModel, ClearCredentials, UpdateCredentials } from '@data/auth/auth.actions';
import { Credentials, OauthTokenRequest } from '@data/auth/auth.interface';
import { NotificationService } from '@shared/services/notification.service';
import { addOauthHeader } from './oauth';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  @Select(AuthState) credentials$: Observable<AuthStateModel>;
  credentials: AuthStateModel;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private readonly store: Store,
  ) {
    this.credentials$.subscribe((res) => (this.credentials = res));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (this.isBlob(error)) {
          return this.handleBlobError(request, next, error);
        } else {
          return this[this.getMethodNameFromStatus(error.status)](request, next, error);
        }
      }),
    );
  }

  /**
   * Client errors
   *
   * @param request
   * @param next
   * @param error
   * @private
   */
  private handle4xxError(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    switch (error.status) {
      case 401:
        return this.handle401Error(request, next, error) as Observable<HttpEvent<any>>;

      case 422:
        return this.handle422Error(request, next, error) as Observable<HttpEvent<any>>;

      default:
        this.notificationService.notifyError(error.error.message);
        return throwError(error.error.message);
    }
  }

  /**
   * Server errors
   *
   * @param request
   * @param next
   * @param error
   * @private
   */
  private handle5xxError(request: HttpRequest<any>, next: HttpHandler, error: unknown): Observable<HttpEvent<any>> {
    const message = 'Server Error';
    this.notificationService.notifyError(message);
    return throwError(message);
  }

  /**
   * Unknown errors
   *
   * @param request
   * @param next
   * @param error
   * @private
   */
  private handleUnknownError(request: HttpRequest<any>, next: HttpHandler, error: unknown): Observable<HttpEvent<any>> {
    const message = 'Unknown Error';
    this.notificationService.notifyError(message);
    return throwError(message);
  }

  /**
   * 401 error
   *
   * @param request
   * @param next
   * @param error
   * @private
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    if (request.url.includes('oauth/token')) {
      const message = `${error.error.message} (${error.error.hint})`;
      this.notificationService.notifyError(message);
      this.store.dispatch(new ClearCredentials());
      void this.router.navigate([`/auth/login`]);
      return throwError(message);
    }

    if (request.url.includes('forgot-password')) {
      const message = `${error?.error?.message}`;
      this.notificationService.notifyError(message);
      return throwError(message);
    }

    if (request.url.includes('reset-password')) {
      const message = `${error?.error?.message}`;
      this.notificationService.notifyError(message);
      return throwError(message);
    }

    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      const body = this.createBody();
      if (body.refresh_token) {
        return this.http.post(`/oauth/token`, body).pipe(
          map((c: Credentials) => {
            this.store.dispatch(
              new UpdateCredentials({
                ...c,
                ...{
                  impersonating_as: null,
                },
              }),
            );
            return c;
          }),
          switchMap((c: Credentials) => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(c.access_token);
            return next.handle(addOauthHeader(request, c.token_type, c.access_token));
          }),
          catchError((error) => {
            this.refreshTokenInProgress = false;
            return throwError(error);
          }),
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(addOauthHeader(request, 'Bearer', token))),
    );
  }

  private handle413Error() {
    const message = 'Nie możemy obsłużyć Twojego żądania, ponieważ dane są zbyt duże';
    this.notificationService.notifyError(message);
    return throwError(message);
  }

  private handle422Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    const _error: {
      message: string;
      errors: object;
    } = error.error;

    let displayingErrorMessage = '';
    const messages = _error.errors;
    if (error.error.errors) {
      for (const [key] of Object.entries(messages)) {
        Object.values(messages[key]).forEach((data: string[], index) => {
          if (!displayingErrorMessage.includes(data[index])) {
            index === 0 ? (displayingErrorMessage += data) : (displayingErrorMessage += `\n - ${data}`);
          }
        });
      }
      this.notificationService.notifyError(displayingErrorMessage);
      return throwError(error.error);
    } else {
      this.notificationService.notifyError(error.error.message);
      return throwError(error.error.message);
    }
  }

  private handleBlobError(request: HttpRequest<any>, next: HttpHandler, err: any) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        try {
          const message = JSON.parse((e.target as any).result);
          reject(
            new HttpErrorResponse({
              error: message,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url || undefined,
            }),
          );
          this.notificationService.notifyError(message);
        } catch (e) {
          reject(err);
        }
      };
      reader.onerror = (e) => {
        reject(err);
      };
      reader.readAsText(err.error);
    });
  }

  private getMethodNameFromStatus(status: number) {
    if (status == 0) {
      return 'handle413Error';
    }
    if (status >= 400 && status < 500) {
      return 'handle4xxError';
    }
    if (status >= 500) {
      return 'handle5xxError';
    }
    return 'handleUnknownError';
  }

  private createBody() {
    const body: OauthTokenRequest = {
      refresh_token: this.credentials.refresh_token,
      grant_type: 'refresh_token',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
    };
    return body;
  }

  private isBlob(err: unknown) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  }
}
