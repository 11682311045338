import { HttpClient } from '@angular/common/http';
import { Create, Id } from '@core/http/crud-model';
import { Observable } from 'rxjs';
import { DispositionPdfStoreRequest } from '@data/dispositions/dispositions.model';
import { DataResource } from '@shared/interfaces/data-resource';
import { MediaResource } from '@data/media/media.model';

const ENDPOINT = '/pdf';

export class DispositionPdfService implements Create<DispositionPdfStoreRequest, MediaResource> {
  constructor(private httpClient: HttpClient, private endpoint: string, private id: Id) {}

  create(params: DispositionPdfStoreRequest): Observable<DataResource<MediaResource>> {
    const formData = new FormData();
    formData.append('file', params.file);
    if (params.description) {
      formData.append('description', params.description);
    }
    if (params.roles_groups_names) {
      params.roles_groups_names.forEach((role) => {
        formData.append('roles_groups_names[]', role);
      });
    }
    return this.httpClient.post<DataResource<MediaResource>>(
      `${this.endpoint}/${this.id.toString()}${ENDPOINT}/store`,
      formData
    );
  }

  generate(): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.endpoint}/${this.id.toString()}${ENDPOINT}/generate`, {
      responseType: 'blob' as 'json',
    });
  }
}
