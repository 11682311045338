import { Create, Id, Update } from '@app/@core/http/crud-model';
import { HttpClient } from '@angular/common/http';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { Observable } from 'rxjs';
import {
  AttachmentPostRequest,
  AttachmentResource,
  AttachmentUpdateRolesPatchRequest,
} from '@data/attachment/attachment.model';

const ENDPOINT = '/media';

export class AttachmentService
  implements
    Create<AttachmentPostRequest, AttachmentResource>,
    Update<AttachmentUpdateRolesPatchRequest, AttachmentResource> {
  constructor(private httpClient: HttpClient, private endpoint: string, private id: Id) {}

  create(params: AttachmentPostRequest): Observable<DataResource<AttachmentResource>> {
    const formData = new FormData();
    formData.append('file', params.file);
    if (params.roles_groups_names) {
      params.roles_groups_names.forEach((role) => {
        formData.append('roles_groups_names[]', role);
      });
    }
    return this.httpClient.post<DataResource<AttachmentResource>>(`${this.endpoint}/${this.id}${ENDPOINT}`, formData);
  }

  update(id: Id, params: AttachmentUpdateRolesPatchRequest): Observable<DataResource<AttachmentResource>> {
    return this.httpClient.patch<DataResource<AttachmentResource>>(
      `${this.endpoint}/${this.id}${ENDPOINT}/${id}/update-roles`,
      params
    );
  }
}
