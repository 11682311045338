import { getLocaleFirstDayOfWeek, WeekDay } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { CalendarDateFormat, CalendarTypes, DEFAULT_CALENDAR_TYPE } from './calendar.constants';
import { RangeTime } from './calendar.interface';
import { addMonths, areDatesInSameMonth, isValidDate, setDate, startOfDay, startOfMonth } from './date-utils';

@Component({
  selector: 'wchfs-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
    { provide: ControlContainer, useExisting: NgForm },
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
})
export class CalendarComponent implements AfterContentInit, ControlValueAccessor, OnChanges, OnInit {
  CalendarTypes = CalendarTypes;
  calendarType = DEFAULT_CALENDAR_TYPE;

  months!: readonly Date[];
  touched = false;
  disabled = false;
  showMonthStepper = true;
  activeDate = startOfDay(new Date());
  activeMonth?: Date;
  opened = false;
  selectedLastDaysRange: any;
  @ViewChild('calendar') calendar: any;
  @Input() dateFormat: string;
  @Input() rangeControlStart: any;
  @Input() rangeControlEnd: any;
  @Input() min?: Date | null;
  @Input() showSelectButton = false;
  @Input() isRangePicker = false;
  @Output() valueChange = new EventEmitter<Date>();
  private numberOfMonths = 1;
  private onChange?: (updatedValue: Date | Date[]) => void;
  private onTouched?: () => void;
  private currentValue: Date;
  private currentLocale?: string;
  private setFirstDayOfWeek?: keyof typeof WeekDay;
  private setFirstMonth?: Date | null;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(LOCALE_ID) private localeId: string,
    private elementRef: ElementRef,
  ) {}

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(date: Date | string | undefined) {
    if (!date) {
      this.activeDate = startOfDay(new Date());
      this.currentValue = null;
      return;
    }

    if (isValidDate(date)) {
      this.currentValue = date;
    } else {
      this.currentValue = this.convertDateFormatHOT_FIX(date);
    }

    this.onActiveDateChange(this.currentValue);
  }

  @Input()
  get locale() {
    return this.currentLocale;
  }

  set locale(locale: string | undefined) {
    this.currentLocale = locale || this.localeId;
  }

  @Input()
  get firstDayOfWeek() {
    return this.setFirstDayOfWeek || this.getDefaultFirstDayOfWeek();
  }

  set firstDayOfWeek(firstDayOfWeek: keyof typeof WeekDay) {
    this.setFirstDayOfWeek = firstDayOfWeek;
  }

  get firstMonth(): Date | undefined | null {
    return this.setFirstMonth;
  }

  @Input()
  set firstMonth(firstMonth: Date | undefined | null) {
    this.setFirstMonth = firstMonth;
    this.activeMonth = this.setFirstMonth || undefined;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && this.opened) {
      this.opened = false;
    }
  }

  trackByMilliseconds = (_: number, month: Date) => {
    return this.showMonthStepper || month.getTime();
  };

  ngOnInit() {
    if (!this.locale) {
      this.locale = this.localeId;
    }
  }

  ngAfterContentInit() {
    this.months = this.getMonths();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.numberOfMonths && !changes.numberOfMonths.firstChange) ||
      (changes.firstMonth && !changes.firstMonth.firstChange)
    ) {
      this.months = this.getMonths();
    }
  }

  openCalendar() {
    this.opened = !this.opened;
  }

  changeCalendarType(calendarType: CalendarTypes) {
    this.calendarType = calendarType;
  }

  onActiveDateChange(activeDate: Date) {
    this.activeDate = activeDate;

    if (!areDatesInSameMonth(this.activeDate, this.activeMonth || new Date())) {
      this.activeMonth = startOfMonth(this.activeDate);
      if (this.showMonthStepper) {
        this.months = this.getMonths();
      }
    }
  }

  onActiveMonthChange(activeMonth: Date) {
    this.activeMonth = activeMonth;
    this.activeDate = setDate(this.activeMonth, this.activeDate.getDate());
    this.months = this.getMonths();
  }

  onSelectRange(date: Date, isStart = true) {
    if (isStart) {
      this.rangeControlStart.setValue(date);
      this.rangeControlEnd.setValue(undefined);
      this.onActiveMonthChange(date);
    } else {
      this.rangeControlEnd.setValue(date);
      this.opened = false;
    }
  }

  onSelect(date: Date, shouldCloseCalendar = false) {
    if (this.isRangePicker) {
      this.onSelectRange(date);
      return;
    }

    if (this.showSelectButton) {
      this.value = date;
      this.onActiveMonthChange(date);
      return;
    } else if (shouldCloseCalendar) {
      this.opened = false;
    }

    if (!this.disabled) {
      this.value = date;
      this.onActiveMonthChange(date);
      this.changeValueControl(date);
    }
  }

  selectToday() {
    this.onSelect(startOfDay(new Date()));
    this.onSelect(startOfDay(new Date()));
  }

  selectLastDays(range: RangeTime) {
    this.selectedLastDaysRange = range;
  }

  changeDateByButton() {
    this.changeValueControl(this.value as Date);
    this.opened = false;
  }

  changeRangeByButton(range: RangeTime) {
    this.rangeControlStart.setValue(range.start);
    this.rangeControlEnd.setValue(range.end);
    this.opened = false;
  }

  cancelRangePicker() {
    this.rangeControlStart.setValue('', { emitEvent: false });
    this.rangeControlEnd.setValue('');
  }

  changeValueControl(date: Date) {
    this.valueChange.emit(date);
    if (this.onChange) {
      this.onChange(date);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: Date) {
    this.value = isValidDate(value) ? startOfDay(value) : undefined;
    this.changeDetectorRef.markForCheck();

    if (this.showMonthStepper && this.value) {
      this.activeMonth = this.value;
      this.months = this.getMonths();
    }
  }

  registerOnChange(onChangeCallback: (updatedValue: Date) => void) {
    this.onChange = onChangeCallback;
  }

  registerOnTouched(onTouchedCallback: () => void) {
    this.onTouched = () => {
      this.touched = true;
      onTouchedCallback();
    };
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  private convertDateFormatHOT_FIX(date: string): Date {
    // dd/MM/yyyy case
    if (this.dateFormat === CalendarDateFormat.ddMMyyyy) {
      let day, month, year;
      day = date?.slice(0, 2);
      month = date?.slice(3, 5);
      year = date?.slice(6, 10);
      return this.removeOffset(new Date(`${month}/${day}/${year}`));
    }
    return this.removeOffset(new Date(date));
  }

  private getMonths() {
    const firstMonth = (this.showMonthStepper ? this.activeMonth : this.firstMonth) || new Date();
    const startOfFirstMonth = startOfMonth(firstMonth);
    return Array.from({ length: this.numberOfMonths }, (_, index) => addMonths(startOfFirstMonth, index));
  }

  private getDefaultFirstDayOfWeek() {
    return WeekDay[getLocaleFirstDayOfWeek(this.locale)] as keyof typeof WeekDay;
  }

  private removeOffset(date: Date): Date {
    const _date = date;
    const offset =
      _date.getTimezoneOffset() / 60 > 0
        ? -Math.abs(Math.floor(_date.getTimezoneOffset() / 60))
        : Math.abs(Math.floor(_date.getTimezoneOffset() / 60));
    _date.setHours(_date.getHours() - offset);
    return _date;
  }
}
