export const enum RippleState {
  FADING_IN,
  VISIBLE,
  FADING_OUT,
  HIDDEN,
}

export interface RippleConfig {
  color?: string;
  centered?: boolean;
  radius?: number;
  persistent?: boolean;
  animation?: RippleAnimationConfig;
  terminateOnPointerUp?: boolean;
}

export interface RippleAnimationConfig {
  enterDuration?: number;
  exitDuration?: number;
}

export class RippleRef {
  state: RippleState = RippleState.HIDDEN;

  constructor(
    private renderer: { fadeOutRipple(ref: RippleRef): void },
    public element: HTMLElement,
    public config: RippleConfig,
  ) {}

  fadeOut(): void {
    this.renderer.fadeOutRipple(this);
  }
}
