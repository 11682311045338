import { TyreAdditionalState, TyreLogInDeposit, TyreLogOnCar } from '@data/tyres/tyres.model';
import { Service, ServiceGroup, TyreState } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { Id } from '@core/http/crud-model';

export const MAX_TREAD_DEPTH = 15;
export const MINIMAL_DIFFERENCE_BETWEEN_OLD_AND_NEW_TREAD_DEPTH = 0;

export const canTransferTyreToCar = (tyre: TyreLogInDeposit): boolean => {
  if (TyreState.nontransferableToCar().includes(tyre.data.tyre_state?.value)) {
    return false;
  }

  return !depositActionInProgress(tyre);
};

export const canTransferTyreToDeposit = (tyre: TyreLogOnCar): boolean => {
  return !depositActionInProgress(tyre);
};

export const depositActionInProgress = (tyre: TyreLogOnCar): boolean => {
  return !!tyre.data.tyre_additional_states.find((state) => TyreAdditionalState.depositAction().includes(state.value));
};

export const groupBy = (list: Service[], keyGetter: (v: Service) => Id): ServiceGroup[] => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const grouped = Array.from(map, function (entry) {
    const isNew = entry[1].filter((service: Service) => service.meta.isNew).length > 0;
    return createServiceGroup(entry[1][0], entry[1].length, isNew);
  });

  return grouped;
};

export const createServiceGroup = (service: Service, quantity: number, isNew: boolean): ServiceGroup => {
  return {
    service: service.service,
    meta: {
      ...service.meta,
      quantity: quantity,
      isNew,
    },
  };
};

export const isSale = (service: Service): boolean => !!service.service.is_sale;
export const isNotSale = (service: Service): boolean => !service.service.is_sale;
export const isProduct = (s: Service): boolean => s.service.is_product;
export const isNotProduct = (s: Service): boolean => !s.service.is_product;
export const isWithoutDamageType = (s: Service): boolean => !s.meta.damageType;
export const isWithDamageType = (s: Service): boolean => !!s.meta.damageType;
