import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReservationDayResource } from '@data/reservations/reservations.model';

@Component({
  selector: 'app-day-card',
  templateUrl: './day-card.component.html',
  styleUrls: ['./day-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayCardComponent {
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() smallText: string;
  @Input() bigText: string;
}
